import { ComponentProps, forwardRef } from 'react'
import Link from 'next/link'

// eslint-disable-next-line react/display-name
const NextLink = forwardRef<HTMLAnchorElement, ComponentProps<typeof Link>>(
  ({ href, children, ...rest }, ref) => {
    return (
      <Link href={href} ref={ref} {...rest}>
        {children}
      </Link>
    )
  },
)

export default NextLink
