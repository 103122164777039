import { ComponentProps, FC } from 'react'
import clsx from 'clsx'

export const CategoryBadge: FC<ComponentProps<'div'>> = ({ children, className, ...props }) => {
  return (
    <div
      className={clsx(
        'text-deep-teal-500 border-l-2 border-l-orange-500 pl-2 text-xs font-medium leading-4',
        className,
      )}
      {...props}
    >
      {children}
    </div>
  )
}
