import { ComponentProps, FC } from 'react'
import clsx from 'clsx'

export const ArticleCardLoading: FC<ComponentProps<'div'> & { showCategories?: boolean }> = ({
  className,
  showCategories = true,
  ...props
}) => {
  return (
    <div className={clsx('flex animate-pulse gap-2', className)} {...props}>
      <div className="flex-1 flex-shrink-0 overflow-hidden rounded">
        <div className="aspect-[15/10] h-full w-full bg-slate-200 object-cover object-left-top" />
      </div>

      <div className="flex flex-1 flex-col justify-between gap-4">
        <div className="space-y-0.5">
          <div className="h-5 w-full bg-slate-200" />
          <div className="h-5 w-full bg-slate-200" />
          <div className="h-5 w-[40%] bg-slate-200" />
        </div>
        {showCategories && <div className="h-3 w-[60%] bg-slate-200" />}
      </div>
    </div>
  )
}
