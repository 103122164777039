import { createQuery, createImage as createAmityImage } from '@amityco/ts-sdk'
import { runQueryPromise } from './runQueryPromise'

export const createImage = async (file: File): Promise<Amity.File<'image'>> => {
  const formData = new FormData()
  formData.append('files', file)

  const query = createQuery(createAmityImage, formData)
  const { data } = await runQueryPromise(query)
  return data[0]
}
