import { runQuery } from '@amityco/ts-sdk'

export const runQueryPromise = async <Args extends any[], Returned>(
  query: Amity.Query<Args, Returned>,
  useCache = true,
): Promise<Amity.Snapshot<Returned>> => {
  return new Promise<Amity.Snapshot<Returned>>((resolve, reject) => {
    runQuery(query, (snapshot) => {
      if (snapshot.error) {
        reject(snapshot.error)
        return
      }
      if (snapshot.loading) return
      if (useCache) {
        resolve(snapshot)
      } else if (snapshot.origin !== 'local') {
        resolve(snapshot)
      }
    })
  })
}
