import { FC, ReactNode, useCallback, useContext, useEffect, useState } from 'react'
import { axios } from 'api/lib'
import { AxiosRequestConfig } from 'axios'
import { AuthContext } from './AuthContext'

// Make sure that that interceptor used in both projects
const handleApiError = (error: any) => {
  // Override the default Axios HTTP error message
  // with the message received from the server.
  if (error.response?.data?.message) {
    throw {
      ...error,
      code: error.response.data.code,
      message: error.response.data.message,
    }
  }
  throw error
}

export const AxiosAuthInterceptorInitializer: FC<{ children: ReactNode }> = ({ children }) => {
  const { getAccessToken } = useContext(AuthContext)
  const [isInitialized, setIsInitialized] = useState(false)

  const appendAuthHeader = useCallback(
    async (config: AxiosRequestConfig) => {
      if (
        config.url?.startsWith('/api') &&
        !config.url?.startsWith('/api/public') &&
        !config.url?.startsWith('/api/auth0/public') &&
        !config.headers?.Authorization // Do NOT override header if it's explicitly provided.
      ) {
        const accessToken = await getAccessToken()
        config!.headers!.Authorization = `Bearer ${accessToken}`
      }
      return config
    },
    [getAccessToken],
  )

  useEffect(() => {
    const authInterceptorId = axios.interceptors.request.use(appendAuthHeader)
    const errorInterceptorId = axios.interceptors.response.use(
      (response) => response,
      handleApiError,
    )
    setIsInitialized(true)
    return () => {
      axios.interceptors.request.eject(authInterceptorId)
      axios.interceptors.request.eject(errorInterceptorId)
    }
  }, [appendAuthHeader])

  return <>{isInitialized && children}</>
}
