import type { AxiosRequestConfig } from 'axios'
import useSWR, { SWRConfiguration, SWRResponse } from 'swr'
import axios from './axios'

export type AxiosRequestConfigWithoutURL = Omit<AxiosRequestConfig, 'url' | 'method'>

export default function useAxios<Data = unknown, Error = unknown>(
  url: string,
  swrConfig?: SWRConfiguration,
  axiosConfig?: AxiosRequestConfigWithoutURL,
): SWRResponse<Data, Error> {
  return useSWR<Data, Error>(
    url,
    () => axios.get(url, axiosConfig).then((res) => res.data),
    swrConfig,
  )
}
