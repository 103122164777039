import { FC, HTMLAttributes, ReactNode } from 'react'
import { Card } from '../../containers/Card'

export interface LayoutCardProps extends HTMLAttributes<HTMLDivElement> {
  readonly title: string
  readonly subtitle?: string | ReactNode
}

const LayoutCard: FC<LayoutCardProps> = ({ title, subtitle, children }) => {
  return (
    <>
      <div className="flex-col my-8 mx-auto max-w-md">
        <h1 className="text-3xl text-rangitoto-900 text-center leading-9 font-bold">{title}</h1>

        {subtitle && <div className="text-rangitoto-900 mt-2">{subtitle}</div>}
      </div>
      <Card className="mb-8 px-10 py-10 sm:p-10 w-full md:w-auto mx-auto max-w-md sm:min-w-[448px]">
        {children}
      </Card>
    </>
  )
}

export default LayoutCard
