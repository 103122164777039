import { ComponentProps, FC } from 'react'
import { useAmityQuery } from 'amity/lib'
import clsx from 'clsx'
import { LoadMoreButton } from 'ui/components/navigation'
import { GroupCard } from './GroupCard'
import { GroupCardLoadingState } from './GroupCardLoadingState'

export interface GroupListProps extends ComponentProps<'div'> {
  readonly createQueryFn: Parameters<typeof useAmityQuery>[0]
  readonly showGroupAccessBadge?: boolean
  readonly emptyStateText: string
}

const GroupList: FC<GroupListProps> = ({
  createQueryFn,
  emptyStateText,
  showGroupAccessBadge,
  ...props
}) => {
  const { data, firstLoaded, hasNextPage, loadNextPage, loading } = useAmityQuery(createQueryFn)

  return (
    <div {...props}>
      {!firstLoaded ? (
        <ul
          role="list"
          data-testid="groups-loading-state"
          className={clsx('animate-pulse space-y-6')}
        >
          <GroupCardLoadingState />
          <hr className="mt-6 h-[1px] bg-slate-200 sm:ml-20" />
          <GroupCardLoadingState />
        </ul>
      ) : (
        <>
          <ul role="list" className="space-y-4">
            {data?.length > 0 ? (
              <>
                {data?.map((group) => (
                  <li key={group.communityId}>
                    <GroupCard group={group} showGroupAccessBadge={showGroupAccessBadge} />
                  </li>
                ))}
              </>
            ) : (
              <div className="text-deep-teal-300 flex items-center justify-center rounded-lg bg-white py-10 px-7 text-center shadow">
                {emptyStateText}
              </div>
            )}
          </ul>
          {hasNextPage && <LoadMoreButton onLoadMore={loadNextPage} isLoading={loading} />}
        </>
      )}
    </div>
  )
}

export default GroupList
