import { ComponentProps, FC } from 'react'
import clsx from 'clsx'
import logo from './meetperry-mobile-taupe.svg'

export const SmallLogo: FC<ComponentProps<'img'>> = ({ className, ...props }) => {
  return (
    <img
      className={clsx(className, 'block mt-[4px] h-[19px] w-[34px]')}
      src={logo.src}
      {...props}
    />
  )
}
