import { ComponentProps, FC } from 'react'
import { useAmityFile } from 'amity/src/lib'
import { Avatar } from './index'

type AmityAvatarProps = Pick<ComponentProps<'div'>, 'className'> & {
  fileId: string
}

export const AmityAvatar: FC<AmityAvatarProps> = ({
  fileId,
  className,
}) => {
  const file = useAmityFile(fileId)

  return (
    <Avatar className={className} src={file?.fileUrl} />
  )
}