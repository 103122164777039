import { FC } from 'react'
import { createQuery, queryCommunities } from 'amity/src/lib'
import GroupList from './GroupList'

const GroupsHome: FC = () => {
  return (
    <>
      <h1 className="text-deep-teal-500 font-brand text-3xl font-medium lining-nums">Groups</h1>

      <h2 className="text-deep-teal-900 mt-6 mb-4 text-base font-bold">My groups</h2>
      <GroupList
        createQueryFn={createMyGroupsQuery}
        showGroupAccessBadge={true}
        emptyStateText="Your groups will appear here with information about their membership and updates as soon as you join some"
      />

      <h2 className="text-deep-teal-900 mt-6 mb-4 text-base font-bold">All public groups</h2>
      <GroupList
        createQueryFn={createPublicGroupsQuery}
        showGroupAccessBadge={true}
        emptyStateText="You have joined all currently available groups. If you'd like to suggest a new group, please email membership@meetperry.com"
      />
    </>
  )
}

export const createMyGroupsQuery = (page?: Amity.Page): any => {
  return createQuery(queryCommunities, {
    membership: 'member',
    isDeleted: false,
    sortBy: 'lastCreated',
    page,
  })
}

export const createPublicGroupsQuery = (page?: Amity.Page): any => {
  return createQuery(queryCommunities, {
    membership: 'notMember',
    isDeleted: false,
    sortBy: 'lastCreated',
    page,
  })
}

export default GroupsHome
